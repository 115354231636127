import $http from "./index";

//加载动画及错误信息提示    1：表示显示加载动画，不提示错误信息   2：不显示加载动画，提示错误信息  3：表示即显示加载动画，又错误提示
export const buildHeaders = (type, loadMsg = "正在提交数据，请稍后……") => {
  let data = {};
  if (type === 1) {
    //表示显示加载动画，不提示错误信息
    data = {
      LoadMsg: encodeURIComponent(loadMsg),
    };
  } else if (type === 2) {
    //不显示加载动画，提示错误信息
    data = {
      ShowError: true,
    };
  } else if (type === 3) {
    //表示即显示加载动画，又错误提示
    data = {
      LoadMsg: encodeURIComponent(loadMsg),
      ShowError: true,
    };
  }
  return data;
};

//获取配置项
export const home_getconfig = () => {
  return $http.get("/web/Home/GetConfig");
};

//获取菜单
export const home_getmenu = () => {
  return $http.get("/web/Home/GetMenu");
};

//获取广告
export const home_getadvertisement = () => {
  return $http.get("/web/Home/GetAdvertisement");
};

//获取首页轮播的新闻
export const home_getcarousel = () => {
  return $http.get("/web/Home/GetCarousel");
};

//获取在首页内容显示的二级菜单
export const home_getsecendmenu = () => {
  return $http.get("/web/Home/GetSecendMenu");
};

//获取首页显示的模块
export const home_getmodule = () => {
  return $http.get("/web/Home/GetModule");
};

//获取在首页内容显示的二级菜单
export const home_getonlyimage = () => {
  return $http.get("/web/Home/GetOnlyImage");
};

//获取首页特色业务菜单
export const home_getfeaturemenu = () => {
  return $http.get("/web/Home/GetFeatureMenu");
};

//获取首页出行服务菜单
export const home_getservicemenu = () => {
  return $http.get("/web/Home/GetServiceMenu");
};

//获取新闻详情
export const home_getnews = (id) => {
  return $http.get("/web/Home/GetNews/" + id);
};

//获取新闻详情
export const home_getnewsbymenuid = (id) => {
  return $http.get("/web/Home/GetNewsByMenuId/" + id);
};

//获取新闻列表
export const home_getnewspage = (id, params) => {
  return $http.get("/web/Home/GetNewsPage/" + id, { params });
};

//获取新闻列表
export const home_getnewssearch = (params) => {
  return $http.get("/web/Home/GetNewsSearch", { params });
};

//获取新闻列表
export const home_getnewslist = (id) => {
  return $http.get("/web/Home/GetNewsList/" + id);
};

//生成验证码
export const home_buildvalidate = () => {
  return $http.get("/web/Home/BuildValidate");
};

//在线留言
export const home_addwords = (data) => {
  return $http.post("/web/Home/AddWords", data, {
    headers: buildHeaders(3, "正在提交数据，请稍后……"),
  });
};
